import {
  AbsoluteCenter,
  background,
  border,
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IdentityService } from "../../service/identity/identity.service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MagiclinkService } from "../../service/magiclink/magiclink.service";
import { RootState, destroyAllState } from "../../store";
import { FormInput } from "../../components/form/controls/form.input";
import { ButtonCustom } from "../../components/form/controls/form.button";
import { Block, BorderColor } from "@mui/icons-material";

const MAINTENANCE_MODE = false;

const Login: React.FC<any> = (props: any) => {
  const magiclinkService = new MagiclinkService();
  const identityService = new IdentityService();

  const navigate = useNavigate();

  const sessionToken = useSelector(
    (state: RootState) => state.token.accessToken
  );

  const [formState, setFormState] = useState({ email: "" });
  const [loading, setLoading] = useState(false);

  const onInputChange = (event: any) => {
    let { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const init = async () => {
    if (!!sessionToken?.length) {
      navigate("/app/dashboard");
      return;
    }

    await magiclinkService.logout();
    destroyAllState();
  };

  useEffect(() => {
    init();
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    await identityService
      .exists(formState.email)
      .then(async () => {
        setLoading(true);

        await magiclinkService
          .handleLoginWithEmail(formState.email)
          .then(() => {
            // Open empty page and close
            window.open("about:blank", "_self");
            window.close();
          })
          .catch((err) => {
            alert(err);
            return;
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        alert("Account does not exist. Please register first.");
        return;
      });
  };

  const handleSocialLogin = async (provider: "google" | "microsoft") => {
    await magiclinkService.handleLoginWithSocial(provider);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Flex
      h={"100vh"}
      w="100vw"
      bg={{ base: "brand.green.200", md: "#F8F9FC" }}
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      <Flex flexDir={{ base: "column", md: "row" }} h="100%">
        <Box
          pos="relative"
          overflowY="hidden"
          display={{ base: "none", md: "block" }}
          left={0}
          h="100vh"
          w="60vw"
          bg="brand.green.200"
        >
          <Box px="5vw" pos="absolute" bottom="-120px">
            <Image w="100%" src="/assets/homepage-app.svg" />
          </Box>
        </Box>

        <Flex
          w={{ base: "100%", md: "40vw" }}
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          gap="16px"
          h="100%"
        >
          <Flex
            id="test"
            w={{ base: "300px", md: "35vw" }}
            maxW="350px"
            flexDir="column"
            gap="18px"
          >
            <Text
              textAlign="start"
              fontSize={{ base: "36px", md: "46px" }}
              fontWeight="1000px"
              color={{ base: "brand.yellow.200", md: "brand.green.200" }}
              style={{ fontFamily: "zolo-header" }}
            >
              Welcome back.
            </Text>

            {MAINTENANCE_MODE && <MaintenancePage />}

            {!MAINTENANCE_MODE && (
              <>
                <form onSubmit={onSubmit}>
                  <Flex color="black" flexDirection="column" gap="16px">
                    <FormInput
                      name="email"
                      title="Email"
                      required
                      type="email"
                      placeholder="Enter your email address"
                      onChange={onInputChange}
                    />
                    <ButtonCustom type="submit">
                      {!loading
                        ? "Login to ZOLO"
                        : "Waiting for confirmation..."}
                    </ButtonCustom>
                  </Flex>
                </form>
                <Box position="relative" my={{ base: "10px", md: "15px" }}>
                  <Divider display={{ base: "none", md: "block" }} />
                  <AbsoluteCenter px="4">
                    <Flex
                      my="6px"
                      flexDir="column"
                      textAlign="center"
                      justifyContent="center"
                      fontSize="14px"
                      gap="2px"
                    >
                      <Box fontWeight="700" color="gray.400">
                        OR
                      </Box>
                    </Flex>
                  </AbsoluteCenter>
                </Box>
                <Center>
                  <Flex flexDir="column" gap="12px">
                    {/* <ButtonCustom
      style={{ width: "250px" }}
      neutral
      onClick={() => handleSocialLogin("google")}
    >
      <Flex
        flexDir="row"
        justifyContent="space-between"
        alignItems="center"
        px="10px"
      >
        <Image
          src="/assets/icons/google.svg"
          style={{
            width: "18px",
            marginRight: "4px",
          }}
        />
        Login with Google
        <Box w="1px"></Box>
      </Flex>
    </ButtonCustom> */}
                    <ButtonCustom
                      style={{
                        background: windowWidth < 768 ? "white" : "",
                        border:
                          windowWidth < 768
                            ? "2px solid white"
                            : "1px solid #D4D4D8",
                      }}
                      neutral
                      onClick={() => handleSocialLogin("microsoft")}
                    >
                      <Flex
                        flexDir="row"
                        justifyContent="space-between"
                        alignItems="center"
                        px="10px"
                      >
                        <Image
                          src="/assets/icons/microsoft.svg"
                          style={{
                            width: "14px",
                            marginRight: "8px",
                          }}
                        />
                        Login with Microsoft
                        <Box w="1px"></Box>
                      </Flex>
                    </ButtonCustom>
                  </Flex>
                </Center>
                <Box position="relative">
                  <Divider display={{ base: "none", md: "block" }} />
                  <AbsoluteCenter
                    color="gray.400"
                    bg="#F8F9FC"
                    px="4"
                  ></AbsoluteCenter>
                </Box>
                <Text
                  color={{ base: "white", md: "black" }}
                  textAlign="center"
                  fontSize="14px"
                >
                  Don&apos;t have an account?{" "}
                  <Link
                    href="/app/auth/register"
                    color={{ base: "brand.yellow.200", md: "brand.green.200" }}
                    fontWeight="700"
                  >
                    Sign Up
                  </Link>{" "}
                </Text>
                <Text color="gray.200" textAlign="center" fontSize="14px">
                  Looking for our website?
                  <br />
                  <Link href="https://zolo.com.au" fontWeight="700">
                    Click here.
                  </Link>
                </Text>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const MaintenancePage = () => {
  return (
    <Flex flexDir="column" textAlign="center">
      <Box>We are currently under maintenance.</Box>
      <Box>Sorry for the inconvenience.</Box>

      <Box mt="20px">Estimated Resolution: </Box>
      <Box>28 May 2024 11:30AM</Box>
    </Flex>
  );
};

export default Login;
